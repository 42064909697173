.container_result {
    padding-top: 1vh;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    height: 100vh;
}
.result_header_text{
    display: flex;
    flex-direction: column;
    font-size: 3vw;
    color: red;
    width: 100%;
}
.list{
    font-size: 3vw;
    color: #fff;
    list-style: none;
    li{
        display: flex;
        flex-direction: row;
        span:nth-child(1){
            width: 95%;
        }
        span:nth-child(2){
            width: 5%;
        }
    }
}
.question_results{
    display: flex;
    flex-direction: column;
}
.start_again a{
    margin-top: 7vw;
    font-size: 3vw;
}