.container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  padding: 1rem;
  font-size: 3vw;
  .question_side {
    width: 50%;
    padding: 2vw;
    display: flex;
    align-items: center;
    .question {
      position: absolute;
      left: 7vw;
      top: 28vh;
      transform: translate(-7%, 0%);
      font-size: 5vw;
      color: #fff;
    }
  }
  .right_side {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .score {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      list-style: none;
      font-size: 3vw;
      color: #fff;
    }
    .answers {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60%;
      height: 40vw;
      .answer {
        cursor: pointer;
        width: 10vw;
        height: 10vw;
        background: url(../../assets/answer.svg) no-repeat center;
        position: absolute;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          color: rgb(123, 118, 118);
          background: url(../../assets/answer_green.svg) no-repeat center;
        }
      }
      .answer_A {
        left: 70%;
        top: 40%;
        transform: translate(-50%, -50%);
      }
      .answer_B {
        right: 0%;
        top: 50%;
        transform: translate(80%, 0%);
      }
      .answer_C {
        left: 65%;
        bottom: 0%;
        transform: translate(0%, 0%);
      }
    }
  }
}
