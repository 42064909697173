.Rotate {
    display: none;
}

@media screen and (max-width: 600px) {
    .App {
        display: none;
    }

    body {
        background: #2D2D2D url(./assets/rotate.gif) center no-repeat;
    }
}