.start {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 3vw;
  .btn_start svg {
    position: absolute;
    width: 20vw;
    fill: #fff;
    &:hover {
      fill: rgb(123, 118, 118);
    }
  }
  .btn_start {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
    background: none;
    font-family: "Handlee";
    border: none;
    font-size: 3vw;
    &:hover {
      color: rgb(123, 118, 118);
    }
  }
}
